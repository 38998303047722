import React, { Suspense } from 'react';
import "./assets/scss/main.scss";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
import { mediaBreakpoint } from "./componets/global/MediaBreakPointes";
import { useResizeDetector } from 'react-resize-detector';  // Import resize detector

const Header = React.lazy(() => import('./componets/global/Header'));
const NavigationBar = React.lazy(() => import('./componets/global/NavigationBar'));
const Loader = React.lazy(() => import('./componets/global/Loader'));
const CustomerListScreen = React.lazy(() => import('./componets/screens/CustomerListScreen'));
const CustomerDetailedScreen = React.lazy(() => import('./componets/screens/CustomerDetailedScreen'));
const InteractionHistory = React.lazy(() => import('./componets/screens/InteractionHistory'));
const Login = React.lazy(() => import('./componets/screens/Login'));
const ForgetPassword = React.lazy(() => import('./componets/screens/ForgotPassword'));
const ProfileScreen = React.lazy(() => import('./componets/global/PofileScreen'));
const GoogleCallback = React.lazy(() => import('./componets/screens/GoogleCallBack'));

const LoadingBar = () => (
  <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Loader />
  </div>
);

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <RoutesWrapper />
      </AuthProvider>
    </Router>
  );
};

const RoutesWrapper = ({}) => {
  const location = useLocation();
  const publicScreen = location.pathname === '/login' || location.pathname === "/forgetPassword";
  const { isResponsive } = useAuth();
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
      {
        !publicScreen && (
           <>
            <Header />
           </>
        )
      }
        <div className='mainAppWrapper'>
          {!publicScreen && !isResponsive && (
            <div className="leftNavWrapper">
              <NavigationBar />
            </div>
          )}
          <div className={!publicScreen ? "rightContentWrapper" : "mainContent"}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/auth/callback/google" element={<GoogleCallback />} />
              <Route 
                path="/profileScreen" 
                element={
                  <ProtectedRoute>
                    <ProfileScreen />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/" 
                element={
                  <ProtectedRoute>
                    <CustomerListScreen />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/detailedView/:customerId?" 
                element={
                  <ProtectedRoute>
                    <CustomerDetailedScreen />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/interactionHistory/:customerId?" 
                element={
                  <ProtectedRoute>
                    <InteractionHistory />
                  </ProtectedRoute>
                } 
              />
            </Routes>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default App;
