import React, { createContext, useContext, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ls from "local-storage";
import { useNavigate, useLocation } from 'react-router-dom';
import { mediaBreakpoint } from '../componets/global/MediaBreakPointes';
import { useResizeDetector } from 'react-resize-detector';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [role, setRole] = useState("normal");
  const [isLoading, setIsLoading] = useState(true);  // Set isLoading to true initially
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    toast.dismiss();
  }, []);

  // Check token expiry and update authentication state
  const checkTokenExpiry = () => {
    const now = new Date();
    const cookieObj = ls.get("access_token");
  
    if (location.pathname === "/auth/callback/google") {
      setIsLoading(false);
      return;
    }
  
    if (cookieObj) {
      if (now.getTime() <= cookieObj.expiry) {
        setIsAuthenticated(true);
        setUserInfo(cookieObj);
      } else {
        ls.remove("access_token");
        setIsAuthenticated(false);
        if (location.pathname !== "/login") navigate("/login");
      }
    } else {
      setIsAuthenticated(false);
      if (location.pathname !== "/login") navigate("/login");
    }
  
    setIsLoading(false);
  };
  
  useEffect(() => {
    checkTokenExpiry();
  }, []);

  const isResponsive = (mediaBreakpoint?.mobile > width || mediaBreakpoint?.bigTablet >= width);

  return (
    <AuthContext.Provider value={{
        role,
        setRole,
        isAuthenticated,
        setIsAuthenticated,
        userInfo, 
        setUserInfo,
        isLoading,
        setIsLoading,
        isResponsive
      }}>
      <div ref={ref}>
        {children}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
